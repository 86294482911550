// composables/useUtils.js
export function useUtils() {

  const updateStartTimesBelow = (rosters, currentRow, updateFn) => {
      const currentIndex = rosters.findIndex(row => row === currentRow);
      if (currentIndex === -1) return;

      const newStartTime = currentRow.roster_start_time;

      const updatedRosters = rosters.map((roster, index) => {
          if (index > currentIndex) {
          return { ...roster, roster_start_time: new Date(newStartTime) };
          }
          return roster;
      });

      updateFn(updatedRosters);
  };

  const updateEndTimesBelow = (rosters, currentRow, updateFn) => {
      const currentIndex = rosters.findIndex(row => row === currentRow);
      if (currentIndex === -1) return;

      const newEndTime = currentRow.roster_end_time;

      const updatedRosters = rosters.map((roster, index) => {
          if (index > currentIndex) {
          return { ...roster, roster_end_time: new Date(newEndTime) };
          }
          return roster;
      });

      updateFn(updatedRosters);
  };

  const parseTime = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date(); 
    date.setHours(hours, minutes, 0, 0);
    return date;
  };

  const updateMonthAndDay = (roster) => {
    const date = new Date(roster.roster_start_date);
    roster.roster_month = date.toLocaleString('default', { month: 'long' });
    roster.roster_day = date.toLocaleString('default', { weekday: 'long' });
  };

  const getHoursAndMinutes = (timeValue) => {
    if (typeof timeValue === 'string') {
      const [hours, minutes] = timeValue.split(':').map(Number);
      return isNaN(hours) || isNaN(minutes) ? [0, 0] : [hours, minutes];
    } else if (timeValue instanceof Date) {
      return [timeValue.getHours(), timeValue.getMinutes()];
    }
    console.error('Unexpected time format:', timeValue);
    return [0, 0];
  };

  const getDateComponents = (dateValue) => {
    if (dateValue instanceof Date) {
      return [dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate()];
    } else if (typeof dateValue === 'string') {
      const [year, month, day] = dateValue.split('-').map(Number);
      return [year, month - 1, day];
    }
    console.error('Unexpected date format:', dateValue);
    return null;
  };

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    start.setFullYear(1970, 0, 1);
    end.setFullYear(1970, 0, 1);
    if (end < start) {
      end.setDate(end.getDate() + 1);
    }
    const diff = end - start;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    if (isNaN(hours) || hours < 0 || hours > 24) {
      return "ERROR";
    }
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const calculateNetWorkedHours = (shiftStart, shiftEnd, startTime, endTime, startTimeStatus, endTimeStatus, overtimeStatus) => {
    // Convert timestamps to Date objects
    const toDate = (timestamp) => new Date(timestamp);
    
    // Validate time sequence
    const shiftStartDate = toDate(shiftStart);
    const shiftEndDate = toDate(shiftEnd);
    const startTimeDate = toDate(startTime);
    const endTimeDate = toDate(endTime);

    // Only check if the time sequence is valid (end times should be after start times)
    if (shiftEndDate <= shiftStartDate || endTimeDate <= startTimeDate) {
        console.error('Invalid time sequence detected');
        return "ERROR";
    }

    let inAttendanceDuration;
    if (startTimeStatus === "ONTIME" && endTimeStatus === "ONTIME") {
      inAttendanceDuration = calculateDuration(toDate(shiftStart), toDate(shiftEnd));
    } else if (startTimeStatus === "EARLY" && endTimeStatus === "ONTIME") {
      inAttendanceDuration = calculateDuration(toDate(startTime), toDate(shiftEnd));
    } else if (startTimeStatus === "LATE" && endTimeStatus === "ONTIME") {
      inAttendanceDuration = calculateDuration(toDate(startTime), toDate(shiftEnd));
    } else if (startTimeStatus === "LATE" && endTimeStatus === "EARLY") {
      inAttendanceDuration = calculateDuration(toDate(startTime), toDate(endTime));
    } else if (startTimeStatus === "ONTIME" && endTimeStatus === "EARLY") {
      inAttendanceDuration = calculateDuration(toDate(shiftStart), toDate(endTime));
    } else if (startTimeStatus === "ONTIME" && endTimeStatus === "OVERTIME" && overtimeStatus === true) {
      inAttendanceDuration = calculateDuration(toDate(shiftStart), toDate(endTime));
    } else if (startTimeStatus === "LATE" && endTimeStatus === "OVERTIME" && overtimeStatus === true) {
      inAttendanceDuration = calculateDuration(toDate(startTime), toDate(endTime));
    } else if (startTimeStatus === "EARLY" && endTimeStatus === "OVERTIME" && overtimeStatus === true) {
      inAttendanceDuration = calculateDuration(toDate(startTime), toDate(endTime));
    } else if (startTimeStatus === "ONTIME" && endTimeStatus === "OVERTIME" && overtimeStatus !== true) {
      inAttendanceDuration = "OPEN";
    } else if (startTimeStatus === "LATE" && endTimeStatus === "OVERTIME" && overtimeStatus !== true) {
      inAttendanceDuration = "OPEN";
    } else if (startTimeStatus === "EARLY" && endTimeStatus === "OVERTIME" && overtimeStatus !== true) {
      inAttendanceDuration = "OPEN";
    } else if (startTimeStatus === "EARLY" && endTimeStatus === "EARLY") {
      inAttendanceDuration = calculateDuration(toDate(startTime), toDate(endTime));
    } else {
      inAttendanceDuration = "ERROR";
    }
    return inAttendanceDuration;
  };

  const generateBatchName = (date) => {
    const targetDate = date instanceof Date ? date : new Date();
    return targetDate.toISOString().split('T')[0].substring(0, 7);
  };

  const getDayName = (date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
  };

  const getMonthName = (date) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[date.getMonth()];
  };

  const getExactTimestamp = (date) => {
    return Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  };

  const dateFromIsoString = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const updateDateInfo = (event) => {
    const date = new Date(event.event_start);
    event.event_date = date;
    event.event_month = date.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
    event.event_day = date.toLocaleString('default', { weekday: 'long', timeZone: 'UTC' });
  };

  const compareTimestamps = (actual, expected, type) => {
    if (!actual || !expected) {
      return 'UNKNOWN';
    }

    if (!(actual instanceof Date) || !(expected instanceof Date)) {
      console.error('Invalid date format:', { actual, expected });
      return 'ERROR';
    }

    const differenceInMinutes = (actual.getTime() - expected.getTime()) / (1000 * 60);

    if (type === 'IN') {
      if (differenceInMinutes < 0 && differenceInMinutes >= -50) {
        return 'ONTIME';
      } else if (differenceInMinutes >= 0) {
        return 'LATE';
      } else if (differenceInMinutes < -50) {
        return 'EARLY';
      }
    } else if (type === 'OUT') {
      if (differenceInMinutes === 0 || (differenceInMinutes >= 0 && differenceInMinutes <= 50)) {
        return 'ONTIME';
      } else if (differenceInMinutes < 0) {
        return 'EARLY';
      } else if (differenceInMinutes >= 50) {
        return 'OVERTIME';
      }
    }

    return 'ERROR';
  };

  const parseUTCDate = (dateString) => {
    const date = new Date(dateString);
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 
                    date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  };

  return {
    updateStartTimesBelow,
    updateEndTimesBelow,
    parseTime,
    updateMonthAndDay,
    getHoursAndMinutes,
    getDateComponents,
    calculateDuration,
    calculateNetWorkedHours,
    generateBatchName,
    getDayName, 
    getMonthName,
    getExactTimestamp,
    updateDateInfo,
    compareTimestamps,
    parseUTCDate,
    dateFromIsoString
  };
}