<template>
    <div class="flex mb-2">
        <div class="flex-0 flex align-items-center justify-content-center">
          <div class="px-0 py-2">
            <h2 class="m-0 p-0 font-light text-600">Logs for {{ selectedEmployee?.employee_name || 'All Employees' }}</h2>
            <p class="p-0 m-0 pt-1 text-xs text-300"> Showing {{ filteredLogs.length }} results.</p>
          </div>
        </div>
        <div class="flex-1 flex align-items-center gap-2 justify-content-end">
          <div style="position: absolute; right: 30px; bottom: 30px; z-index: 1;" v-if="selectedRows.length > 0" class="shadow-8 m-3">
            <Button v-if="selectedSingleRosterId" @click="viewLogs=true" label="View Logs" class="border-0 text-lg surface-300 hover:surface-500 mr-2 w-full mb-2" />
            <Button @click="openDialog" label="Edit Roster" class="px-4 py-3 text-lg w-full block shadow-3 surface-300 hover:surface-500 w-full" />
          </div>
          <Dropdown 
            v-if="!isDropdownDisabled"
            v-model="selectedUser" 
            :options="userList" 
            :disabled="isRefreshing"
            showClear
            optionLabel="email" 
            placeholder="All Users" 
            class="w-full md:w-10rem"
            @change="handleUserChange" />
          <Dropdown v-if="!isDropdownDisabled" v-model="selectedEmployee" 
            :options="filteredEmployees" :loading="employeesListLoading" :disabled="isRefreshing"
            filter showClear optionLabel="employee_name" placeholder="All Employees" class="w-full md:w-16rem" @change="handleEmployeeSelect" />
          <Dropdown v-if="!isDropdownDisabled" v-model="selectedCompany" 
            :options="companiesList" :loading="employeesListLoading" :disabled="isRefreshing"
            filter showClear optionLabel="company_name" placeholder="All Companies" class="w-full md:w-16rem" @change="handleCompanyChange" />
          <Dropdown 
            v-if="!isDropdownDisabled"
            v-model="selectedSection" 
            :options="sectionList" 
            :disabled="isRefreshing"
            @change="handleSectionChange"
            optionLabel="name" 
            placeholder="Section" 
            class="w-full md:w-12rem" />
          <Calendar 
            v-model="dateRange" 
            selectionMode="range" 
            :showIcon="true" 
            :manualInput="false"
            :disabled="isRefreshing"
            dateFormat="yy-mm-dd"
            placeholder="Select Date Range" 
            class="w-full md:w-16rem"
            :hideOnRangeSelection="true"
            @date-select="handleDateSelect" />
          <Button icon="pi pi-refresh" :loading="isRefreshing" @click="handleRefreshClick" class="surface-300 hover:surface-500" />
      </div>
    </div>  
    <div v-if="filteredLogs.length">
        <DataTable :value="allLogs" aria-label="Roster Table" :loading="allLogsLoading">
            <Column field="user_email" header="User" class="text-600 w-2">
                <template #body="slotProps">
                    <div class="flex items-center gap-2 pt-2">
                        <div>
                            <p class="block font-bold pb-1 m-0 p-0">
                            {{ slotProps.data.user_email }}
                            </p>
                            <p class="block text-xs text-300 m-0 p-0 pb-1">{{ dateFromIsoString(slotProps.data.timestamp) }}</p>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="employee_name" header="Employee Name" class="text-600 w-2">
                <template #body="slotProps">
                    <div class="flex items-center gap-2 pt-2">
                        <div>
                            <p class="block font-bold pb-1 m-0 p-0">
                            {{ slotProps.data.employee_name }}
                            </p>
                            <p class="block text-xs text-300 m-0 p-0 pb-1">{{ slotProps.data.employee_company_name }}</p>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="type" header="Type" class="text-600 w-2" v-if="!isDropdownDisabled">
            <template #body="slotProps">
                <div class="flex items-center gap-2 pt-2">
                <div>
                    <p class="block font-bold pb-1 m-0 p-0">
                        {{ slotProps.data.action }}
                    </p>
                    <p class="block text-xs text-300 m-0 p-0 pb-1">{{ slotProps.data.type }}</p>
                </div>
                </div>
            </template>
            </Column>
            <Column field="action" header="Section" class="text-600 w-2">
            <template #body="slotProps">
                <div class="flex items-center gap-2 pt-2">
                <div>
                    <p class="block font-bold pb-1 m-0 p-0">
                        {{ slotProps.data.table_name }}
                    </p>
                    <p class="block text-xs text-300 m-0 p-0 pb-1">ID #{{ slotProps.data.record_id }}</p>
                </div>
                </div>
            </template>
            </Column>
            <Column field="action" header="Action" class="text-600 w-1" v-if="!isDropdownDisabled">
            <template #body="slotProps">
                <p class="block font-bold text-400 pb-1 m-0 p-0">
                  {{ slotProps.data.priority }}
                </p>
            </template>
            </Column>
            <Column field="changes" header="Changes" class="text-600 w-full">
                <template #body="slotProps">
                    <template v-if="slotProps.data.source === 'firestore'">
                        <div :class="{
                            'text-500': slotProps.data.action === 'INSERT',
                            'text-green-600': slotProps.data.action === 'UPDATE',
                            'text-red-500': slotProps.data.action === 'DELETE'
                        }">
                            {{ getFirestoreChanges(slotProps.data) }}
                        </div>
                    </template>
                    <template v-else-if="slotProps.data.type === 'MANUAL'">
                        <div class="text-blue-500">
                            Manual Entry: {{ getManualEntry(slotProps.data.changes) }}
                        </div>
                    </template>
                    <template v-else-if="slotProps.data.action === 'UPDATE'">
                        <div v-if="hasChanges(slotProps.data.changes)">
                            <div v-for="(value, key) in getChangedValues(slotProps.data.changes)" :key="key" class="mb-2 w-full">
                                <div class="block flex mb-1 w-full">
                                    <span class="line-through text-red-200 w-11rem mr-2">{{ key }}:</span> 
                                    <span class="line-through w-full text-red-200">{{ formatValue(key, slotProps.data.changes.old[key]) }}</span>
                                </div>
                                <div class="block flex w-full">
                                    <span class="mr-2 text-green-600 w-11rem">{{ key }}:</span>
                                    <span class="w-full text-green-600">{{ formatValue(key, value) }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!isManualEntry(slotProps.data.changes)" class="text-gray-500 font-italic">
                            No Change
                        </div>
                    </template>
                    <template v-else-if="slotProps.data.action === 'INSERT'">
                        <div v-for="key in getRelevantFields(slotProps.data.table_name)" :key="key" class="mb-2 w-full">
                            <div v-if="slotProps.data.changes && slotProps.data.changes.hasOwnProperty(key)" class="block flex mb-1 w-full">
                                <span class="text-500 w-11rem mr-2">{{ key }}:</span> 
                                <span class="w-full text-500">{{ formatValue(key, slotProps.data.changes[key]) }}</span>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="slotProps.data.action === 'DELETE'">
                        <div v-for="key in getRelevantFields(slotProps.data.table_name)" :key="key" class="mb-2 w-full">
                            <div v-if="slotProps.data.changes && slotProps.data.changes.hasOwnProperty(key)" class="block flex mb-1 w-full">
                                <span class="line-through text-red-500 w-11rem mr-2">{{ key }}:</span> 
                                <span class="line-through w-full text-red-500">{{ formatValue(key, slotProps.data.changes[key]) }}</span>
                            </div>
                        </div>
                    </template>
                </template>
            </Column>
        </DataTable>
    </div>
    <div v-else>
      No Logs found for this filter. Please refresh to see latest logs.
    </div>
    <Dialog v-model:visible="viewLogs" :style="{ width: '60vw' }" :modal="true">
      <template #header>
        <h2>Logs</h2>
      </template>
      <LogsDialog :selectedRosterIds="selectedSingleRosterId" logType="roster" @handleBack="handleBack" />
    </Dialog>
</template>

<script setup>
import { ref, onMounted, computed, watch, defineProps } from 'vue';
import { useLogs } from "@/composables/api/useLogs";
import { useUtils } from '@/composables/useUtils';
import { useToast } from 'primevue/usetoast';
import { useUserSelections } from '@/composables/useUserSelections';
import { useEmployees } from '@/composables/api/useEmployees';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import LogsDialog from '@/components/dialogs/LogsDialog.vue';
import Calendar from 'primevue/calendar';
import { useLondonUnixTime } from '@/composables/time/useLondonTime';

// Props
const props = defineProps({
  targetEmployeeId: {
    type: Number,
    required: false
  },
  targetDateList: {
    type: String,
    required: false
  },
  targetCompanyId: {
    type: Number,
    required: false
  }
});

// Api: Logs
const { allLogs, allLogsLoading, allLogsError, fetchAllLogs } = useLogs();

// Api: Employees
const { fetchEmployees } = useEmployees();

// Utils: Date
const { dateFromIsoString } = useUtils();

// Utils: User Selections
const { 
  selectedEmployee, 
  employeesListLoading,
  selectedCompany, 
  filteredEmployees,
  companiesList,
  onCompanyChange,
  selectedDateRange
} = useUserSelections();

// Utils: Toast
const toast = useToast();

// Event Handlers: Employee Select
const onEmployeeSelect = async (event) => {
  if (event.value) {
    await refreshData();
  } else {
    // Handle clear selection
    selectedEmployee.value = null;
    await refreshData();
  }
};

// Refs
const editRosterDialog = ref(false);
const createRosterDialog = ref(false);
const viewLogs = ref(false);
const isRefreshing = ref(false);
const dateRange = ref(null);
const selectedRows = ref([]);
const first = ref(0);
const rows = ref(100);
const selectedUser = ref(null);

// Employee List
const userList = [
  { email: 'apex@theorion.pk', id: 'p9ed3AQpLbahjWu1KyOx6a9rNQC2' },
  { email: 'bolt@theorion.pk', id: 'vk8gwHP1K7dxEJaVRH22KRe3juO2' },
  { email: 'horizon@theorion.pk', id: 'SSq75zfwOdXP1fH8GpAAYVkmUsM2' },
  { email: 'kiosk1@theorion.pk', id: 'a2XjRc27Qgbr4KupRZbXmfHVJ1i1' },
  { email: 'kiosk2@theorion.pk', id: 'hH0VSuIM5ubobXo7dk4Lazo8jZk2' },
  { email: 'maverick@theorion.pk', id: 'ZNqxJiQdi0exKPNZs60fU7Kr5R33' },
  { email: 'nexus@theorion.pk', id: 'VMn8DpJx5vfAhVnDwt5CFYOPzNT2' },
  { email: 'spring@theorion.pk', id: 'MrTcoKz4X4TZDdqiNNSMSrh2oK0' },
  { email: 'ultimate@theorion.pk', id: '2UwtKn5tmlNC7zgr7Nz2Iu8vHDm2' }
];

// Computed: Check for targetEmployeeId
const isDropdownDisabled = computed(() => {
    return !!props.targetEmployeeId && props.targetEmployeeId !== 0 || !!props.targetCompanyId && props.targetCompanyId !== 0;
});

// Section List
const sectionList = [
  { name: 'All', code: 'all' },
  { name: 'Attendance', code: 'attendance' },
  { name: 'Roster', code: 'roster' }
];
const selectedSection = ref(sectionList[0]);

// Utils: London Timezone
const { getLondonDate, getStartOfDay, getEndOfDay } = useLondonUnixTime();

// Utils: Date Range
const getDefaultDate = () => {
  const today = getLondonDate();
  return selectedDateRange.value || [
    getStartOfDay(today),
    getEndOfDay(today)
  ];
};

// Utils: Date Range from params
const getDateRangeParams = () => {
  return {
    employeeId: props.targetEmployeeId ?? selectedEmployee.value?.employee_id ?? 0,
    companyId: props.targetCompanyId ?? selectedCompany.value?.company_id ?? 0,
    section: selectedSection.value.code,
    userId: selectedUser.value?.id ?? null,
    startDate: dateRange.value?.[0] ? getLondonDate(dateRange.value[0]).toISOString().split('T')[0] : null,
    endDate: dateRange.value?.[1] ? getLondonDate(dateRange.value[1]).toISOString().split('T')[0] : null
  };
};

// Utils: Check for manual entry
const isManualEntry = (changes) => {
  return changes && changes.manual_entry !== undefined;
};

// Utils: Get manual entry
const getManualEntry = (changes) => {
  if (typeof changes === 'string') {
    try {
      changes = JSON.parse(changes);
    } catch (e) {
      console.error('Error parsing changes JSON:', e);
      return 'Error: Invalid manual entry format';
    }
  }
  return changes.manual_entry || 'No manual entry text';
};

// Utils: Format date
const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}, ${hours}:${minutes}:${seconds}`;
};

// Utils: Format value
const formatValue = (key, value) => {
  const dateFields = ['attendance_in_out', 'roster_start', 'roster_end'];
  if (dateFields.includes(key)) {
    return formatDate(value);
  }
  return value;
};

// Utils: Check for changes
const hasChanges = (changes) => {
  if (isManualEntry(changes)) return true;
  return changes && changes.new && changes.old && 
         Object.keys(changes.new).some(key => changes.new[key] !== changes.old[key]);
};

// Utils: Get changed values
const getChangedValues = (changes) => {
  if (isManualEntry(changes)) {
    return { manual_entry: changes.manual_entry };
  }
  return changes && changes.new && changes.old
    ? Object.keys(changes.new).reduce((acc, key) => {
        if (changes.new[key] !== changes.old[key]) {
          acc[key] = changes.new[key];
        }
        return acc;
      }, {})
    : {};
};

// Utils: Get relevant fields
const getRelevantFields = (tableName) => {
  switch(tableName) {
    case 'attendance':
      return ['attendance_in_out', 'attendance_status'];
    case 'roster':
      return ['roster_start', 'roster_end'];
    default:
      console.warn(`Unknown table name: ${tableName}`);
      return ['id']; // Return a safe default field
  }
};

// Function Error handling
const handleError = (error, context) => {
  console.error(`Error in ${context}:`, error);
  let errorMessage = 'An unexpected error occurred.';
  if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }
  toast.add({
    severity: 'error',
    summary: `Error: ${context}`,
    detail: errorMessage,
    life: 12000
  });
};

// Computed: Filtered logs based on dropdown selections
const filteredLogs = computed(() => {
  if (!Array.isArray(allLogs.value)) {
    console.warn('allLogs.value is not an array:', allLogs.value);
    return [];
  }
  return allLogs.value;
});

// Event Handlers: Date Select
const handleDateSelect = () => {
  if (dateRange.value && Array.isArray(dateRange.value) && dateRange.value[0] && dateRange.value[1]) {
    // Store the date range in Pinia
    selectedDateRange.value = dateRange.value;
    const { employeeId, companyId, section, startDate, endDate } = getDateRangeParams();
    const userEmail = selectedUser.value?.email || null;
    refreshData(employeeId, companyId, section, startDate, endDate, userEmail);
  }
};

// Update refreshData to accept parameters
const refreshData = async (employeeId, companyId, section, startDate, endDate, userEmail) => {
  if (isRefreshing.value) return;
  isRefreshing.value = true;
  try {
    selectedRows.value = [];
    if (employeeId || companyId || startDate || endDate || section || userEmail) {
      await fetchAllLogs(employeeId, companyId, section, startDate, endDate, userEmail);
    } else {
      console.log('No filters applied. Fetching all data or applying default behavior.');
    }
  } catch (err) {
    handleError(err, 'Fetching Logs');
  } finally {
    isRefreshing.value = false;
  }
};

// Event Handlers: Employee Select
const handleEmployeeSelect = (event) => {
  onEmployeeSelect(event);
  refreshData();
};

// Event Handlers: Company Change
const handleCompanyChange = (event) => {
  onCompanyChange(event);
  refreshData();
};

// Event Handlers: Section Change
const handleSectionChange = (event) => {
  selectedSection.value = event.value;
  refreshData();
};

// Event Handlers: Refresh Click
const handleRefreshClick = () => {
  refreshData();
};

// Initialize
onMounted(async () => {
  // First check for stored range, fallback to default if none exists
  dateRange.value = selectedDateRange.value || getDefaultDate();
  await refreshData();
});

// Emits: Handle Back
const handleBack = () => {
  editRosterDialog.value = false;
  createRosterDialog.value = false;
  selectedRows.value = [];
};

// Watch: Selected Employee
watch(selectedEmployee, async (newValue) => {
  if (!props.targetEmployeeId && newValue) {
    await refreshData();
  }
});


// Watch: Refresh Data on Prop Change
watch(() => props.targetEmployeeId || props.targetCompanyId, async (newValue) => {
  if (newValue) {
    await refreshData();
  }
}, { immediate: true });

// Watch: Dropdown Selections
watch([selectedEmployee, selectedUser], () => {
  first.value = 0; 
});

// Watch: Logs Error
watch(allLogsError, (newLogsError) => {
  if (newLogsError) {
    handleError(newLogsError, 'Logs');
  }
});

// Watch: Target Rows
watch(() => props.targetRows, (newValue) => {
  if (newValue) {
    rows.value = newValue;
  }
});

// Lifecycle Hooks: Update
onMounted(async () => {
  try {
    await fetchEmployees();
    
    // If we have a targetEmployeeId, let the watch handle it
    if (!props.targetEmployeeId) {
      await refreshData();
    }
  } catch (error) {
    handleError(error, 'Component Initialization');
  }
});


// Event Handlers: User Change
const handleUserChange = () => {
  refreshData();
};

// Add this new utility function
const getFirestoreChanges = (data) => {
    if (!data.changes?.new) return 'No changes recorded';
    
    switch (data.table_name) {
        case 'complaints':
            return `${data.changes.new.subject}`;
        case 'leaves':
            return `Leave Type: ${data.changes.new.leave_type}`;
        // Add more cases as needed
        default:
            return 'Changes recorded in Firestore';
    }
};
</script>