<template>
  <div class="flex mb-2">
      <div class="flex-0 flex align-items-center justify-content-center">
        <div class="px-0 py-2">
          <h2 v-if="selectedEmployee" class="m-0 p-0 font-light text-600">{{ selectedEmployee.employee_name }} Attendance</h2>
          <h2 v-else-if="selectedCompany" class="m-0 p-0 font-light text-600">{{ selectedCompany.company_name }} Attendance</h2>
          <h2 v-else class="m-0 p-0 font-light text-600">All Attendance</h2>
          <p class="p-0 m-0 pt-1 text-xs text-400"> Showing {{ filteredAttendance.length }} results.</p>
        </div>
      </div>
      <div class="flex-1 flex align-items-center gap-2 justify-content-end">
        <div  v-if="hasSelectedRows" style="position: absolute; right: 30px; bottom: 30px; z-index: 1;" class="shadow-8 m-3">
          <Button @click="viewLogs=true" label="View Logs" class="border-0 text-lg surface-300 hover:surface-500 mr-2 w-full mb-2" />
          <Button @click="editAttendanceDialog=true" label="Edit Attendance" class="px-4 py-3 text-lg w-full block shadow-3 surface-300 hover:surface-500 w-full" />
        </div>
        <Dropdown v-if="!isDropdownDisabled" v-model="selectedEmployee" :options="filteredEmployees" :loading="employeesListLoading" :disabled="isRefreshing"
                  filter showClear optionLabel="employee_name" placeholder="Select Employee" class="w-full md:w-16rem" @change="handleEmployeeSelect" @clear="onEmployeeClear">
          <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                  <div>{{ slotProps.value.employee_name }}</div>
              </div>
              <span v-else class="text-sm text-300">
                  {{ slotProps.placeholder }}
              </span>
          </template>
          <template #option="slotProps">
              <div class="flex align-items-center">
                  <div>{{ slotProps.option.employee_name }}</div>
              </div>
          </template>
        </Dropdown>
        <Dropdown v-if="!isDropdownDisabled" v-model="selectedCompany" :options="companiesList" :loading="employeesListLoading" filter showClear 
                  :disabled="isRefreshing" optionLabel="company_name" placeholder="Select Company" class="w-full md:w-16rem" @change="handleCompanyChange" @clear="onCompanyClear">
          <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                  {{ slotProps.value.company_name }}
              </div>
              <span v-else class="text-sm text-300">
                  {{ slotProps.placeholder }}
              </span>
          </template>
          <template #option="slotProps">
              <div class="flex align-items-center">
                  {{ slotProps.option.company_name }}
              </div>
          </template>
        </Dropdown>
        <Dropdown v-model="selectedType" editable :options="typeList" :disabled="isRefreshing" optionLabel="name" placeholder="Type" class="w-full md:w-11rem">
          <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                  {{ slotProps.value.name }}
              </div>
              <span v-else class="text-sm text-300">
                  {{ slotProps.placeholder }}
              </span>
          </template>
        </Dropdown>   
        <Calendar v-model="dateRange" 
                  selectionMode="range" 
                  :showIcon="true" 
                  :manualInput="false"
                  :disabled="isRefreshing"
                  dateFormat="yy-mm-dd"
                  placeholder="Select Date Range" 
                  class="w-full md:w-20rem"
                  :hideOnRangeSelection="true"
                  @date-select="handleDateSelect" />       
        <Button icon="pi pi-refresh" :loading="isRefreshing" @click="handleRefreshClick" class="border-0 surface-300 hover:surface-500" />
    </div>
  </div>
  <div v-if="filteredAttendance && filteredAttendance.length > 0">
    <DataTable :value="paginatedAttendance" selectionMode="single" v-model:selection="selectedRows" :sortField="sortField" :sortOrder="sortOrder" :loading="isRefreshing">
      <Column field="employee_name" header="Employee" class="text-600 w-2">
          <template #body="slotProps">
              <div class="flex gap-2">
                  <img alt="flag" :src="slotProps.data.employee_photo_url || defaultUserImage" class="max-w-3rem max-h-3rem employee-photo" />
                  <div>
                    <span class="block font-bold">{{ slotProps.data.employee_name }}</span>
                    <span class="block text-xs text-300">{{ slotProps.data.employee_company_name }}</span>
                    <span class="text-xs text-300 mr-2">
                      <span class="mr-2">@{{ slotProps.data.employee_id }}</span>
                      <span class="mr-2">{{ slotProps.data.employee_status }}</span>
                      <span class="mr-2">{{ slotProps.data.employee_department }}</span>
                    </span>
                  </div>
              </div>
          </template>
          <template #footer>
            <div class="flex gap-2">
                <div class="flex surface-100 align-items-center justify-content-center border-round p-2">
                    <span class="text-xs font-light">Records: <span class="font-bold">{{ filteredAttendance.length }}</span></span>
                </div>
            </div>
          </template>
      </Column>
      <Column v-if="!isDropdownDisabled" field="shift_in_day" header="Date" class="text-600 w-1">
        <template #body="slotProps">
            <div class="gap-2 line-height-3">
                  <span class="block">{{ slotProps.data.shift_in_day }}</span>
                  <span class="block text-xs text-300">{{ slotProps.data.shift_in_month }}</span>
            </div>
        </template>
      </Column>
      <Column field="shift_in" header="Roster" class="text-600 w-2">
        <template #body="slotProps">
          <div class="flex gap-2">
              <div class="gap-2 line-height-3">
                    <span class="block flex"><span>{{ slotProps.data.shift_in }}</span></span>
                    <span class="block flex"><span>{{ slotProps.data.shift_out }}</span></span>
              </div>
            <div class="flex surface-100 align-items-center justify-content-center border-round p-2">
              <span class="text-400">{{ calculateRowDurations(slotProps.data).shiftDuration }}</span> 
            </div>
          </div>
        </template>
        <template #footer>
          <div class="flex gap-2">
            <div class="flex surface-100 align-items-center justify-content-center border-round p-2">
              <span class="text-xs font-light">Roster: <span class="font-bold">{{ formatMinutesToTime(totalDuration) }}</span></span>
            </div>
          </div>
        </template>
      </Column>
      <Column field="attendance_checkin" header="Attendance" class="text-600 w-2">
        <template #body="slotProps">
          <div class="flex gap-2">
            <div class="gap-2 line-height-3">
              <span class="block flex"><span>{{ slotProps.data.attendance_checkin }}</span></span>
              <span v-if="slotProps.data.attendance_checkout_unix > 0" class="block flex"><span>{{ slotProps.data.attendance_checkout }}</span></span>
              <span v-else-if="slotProps.data.shift_out_unix < generateCurrentTimeunix" class="block flex text-yellow-800 uppercase text-xs" 
                    style="letter-spacing: 2px;"><span>Not Logout</span>
              </span>
              <span v-else class="block flex text-300 uppercase text-xs" style="letter-spacing: 2px;"><span>Working</span></span>
            </div>
            <div v-if="!isDropdownDisabled && slotProps.data.attendance_checkout_unix > 0" class="flex surface-100 align-items-center justify-content-center border-round p-2 w-3rem">
              <span class="text-400">{{ calculateRowDurations(slotProps.data).attendanceDuration }}</span>
            </div>
            <div v-if="slotProps.data.attendance_checkout_unix > 0" :class="{
                'bg-green-900': slotProps.data.attendance_status_out==='OVERTIME' && slotProps.data.over_below_status === true, 
                'bg-red-800': (
                    (slotProps.data.attendance_status_out==='OVERTIME' && slotProps.data.over_below_status !== true) ||
                    !calculateRowDurations(slotProps.data).netWorkedHours ||
                    calculateRowDurations(slotProps.data).netWorkedHours === '0:00' ||
                    calculateRowDurations(slotProps.data).netWorkedHours === 'ERROR'
                ), 
                'flex surface-100 align-items-center justify-content-center border-round p-2 w-3rem': true
              }">
              <span 
                class="text-400 font-bold" 
                :class="
                  slotProps.data.attendance_status_out==='OVERTIME' 
                  && slotProps.data.over_below_status !== true
                  && slotProps.data.employee_company_id !==1
                  ? 'flash-in-out' : 'relative'">
                {{ calculateRowDurations(slotProps.data).netWorkedHours }}
              </span>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="flex gap-2">
            <div class="flex surface-100 align-items-center justify-content-center border-round p-2">
              <span class="text-xs font-light">Worked: <span class="font-bold">{{ formatMinutesToTime(totalWorkedHours) }}</span></span>
            </div>
            <div class="flex surface-100 align-items-center justify-content-center border-round p-2">
              <span class="text-xs font-light">Net: <span class="font-bold">{{ formatMinutesToTime(totalNetWorkedHours) }}</span></span>
            </div>
          </div>
        </template>
      </Column>
      <Column field="attendance_status_in" header="Status" class="text-600 w-1">
        <template #body="slotProps">
            <div class="gap-2 text-center">
              <Tag severity="info" :class="
                  {'block bg-yellow-800 mb-1 p-0 m-0 border-round-sm': slotProps.data.attendance_status_in==='LATE', 
                  'block bg-cyan-800 mb-1 p-0 m-0 border-round-sm': slotProps.data.attendance_status_in==='EARLY', 
                  'block surface-300 mb-1 p-0 m-0 border-round-sm': slotProps.data.attendance_status_in==='ONTIME'}" style="letter-spacing: 2px;">
                  {{ slotProps.data.attendance_status_in }}
              </Tag>
              <Tag severity="info" :class="
                  {'block bg-green-800 mb-1 p-0 m-0 border-round-sm': slotProps.data.attendance_status_out==='OVERTIME', 
                  'block bg-red-800 mb-1 p-0 m-0 border-round-sm': slotProps.data.attendance_status_out==='EARLY', 
                  'block surface-100 text-300 mb-1 text-xs p-0 m-0 border-round-sm': slotProps.data.attendance_status_out==='NOT', 
                  'block surface-300 mb-1 p-0 m-0 border-round-sm': slotProps.data.attendance_status_out==='ONTIME'}" style="letter-spacing: 2px;">
                  {{ slotProps.data.attendance_status_out }}
              </Tag>
              <Tag v-if="slotProps.data.shift_type==='COVER'" severity="info" :class="
                  {'block bg-teal-800 p-0 m-0 border-round-sm': slotProps.data.shift_type==='COVER'}" style="letter-spacing: 2px;">
                  {{ slotProps.data.shift_type }}
              </Tag>
            </div>
        </template>
      </Column>
      <Column field="attendance_logs" header="Comments" class="text-600 w-3">
          <template #body="slotProps">
            <div v-if="slotProps.data.attendance_logs && slotProps.data.attendance_logs.length > 0">
              <div v-for="(log, index) in formatLogs(slotProps.data.attendance_logs)" :key="index" class="mb-1">
                <span :class="{
                  'text-yellow-200': log.priority==='MEDIUM', 
                  'text-red-400': log.priority==='HIGH', 
                  'text-xs text-400 mr-2': true}">
                  @{{ log.username }}
                </span>
                <span :class="{
                  'text-yellow-200': log.priority==='MEDIUM', 
                  'text-red-400': log.priority==='HIGH', 
                  'text-xs text-400 mr-2': true}">
                  {{ log.message }}
                </span>
              </div>
            </div>
            <div v-else class="text-xs text-200">No comments</div>
          </template>
        </Column>
    </DataTable>
    <Paginator class="mt-2" :rows="rows" :totalRecords="filteredAttendance.length"  v-model:first="first" @page="onPage"></Paginator>
    </div>
    <div v-else class="py-2 text-400">
      No Attendance found for this filter.
    </div>
    <Dialog v-model:visible="editAttendanceDialog" :style="{ width: '65vw' }" :modal="true">
      <template #header>
        <h2>Attendance Edit</h2>
      </template>
      <AttendanceUpdateDialog :selectedIds="selectedRows" @updateSuccess="handleUpdateSuccess" @deleteSuccess="handleDeleteSuccess" @handleBack="handleBack" />
    </Dialog>
    <Dialog v-model:visible="viewLogs" :style="{ width: '60vw' }" :modal="true">
      <template #header>
        <h2>Logs</h2>
      </template>
      <LogsDialog :selectedIds="selectedRows.roster_id" logType="attendance" @handleBack="handleBack" />
    </Dialog>
</template>

<script setup>
import { ref, onMounted, computed, watch, defineProps } from 'vue';
import { useAttendance } from "@/composables/api/useAttendance";
import { useToast } from 'primevue/usetoast';
import { useUtils } from '@/composables/useUtils';
import { useUserSelections } from '@/composables/useUserSelections';
import { useLondonUnixTime } from '@/composables/time/useLondonTime';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Dropdown from 'primevue/dropdown';
import Paginator from 'primevue/paginator';
import Dialog from 'primevue/dialog';
import defaultUserImage from '@/assets/default-user.png';
import AttendanceUpdateDialog from '@/components/dialogs/AttendanceUpdateDialog.vue';
import LogsDialog from '@/components/dialogs/LogsDialog.vue';
import Calendar from 'primevue/calendar';

// Props
const props = defineProps({
  targetEmployeeId: {
    type: Number,
    default: 0,
  },
  targetCompanyId: {
    type: Number,
    default: 0,
  },
  targetDateList: {
    type: String,
  },
});

// API: Attendance  
const { allAttendance, attendanceError, fetchAllAttendance } = useAttendance();

// Utils: Calculate Duration and Net Worked Hours
const { calculateDuration, calculateNetWorkedHours } = useUtils();

// Time: London Time
const { 
  getLondonDate,
  getStartOfDay,
  getEndOfDay,
  generateCurrentTimeunix
} = useLondonUnixTime();

// User Selections: Employee and Company
const { 
  selectedEmployee, 
  employeesListLoading,
  selectedCompany, 
  filteredEmployees,
  companiesList,
  onEmployeeSelect,
  onEmployeeClear,
  onCompanyChange,
  onCompanyClear,
  selectedDateRange
} = useUserSelections();

// Toast: Display Messages
const toast = useToast();

// State: Selected Rows, Sorting, Pagination, Refreshing
const selectedRows = ref(null);
const sortField = ref('attendance_checkin');
const sortOrder = ref(-1);
const rows = ref(100);
const first = ref(0);
const isRefreshing = ref(false);
const editAttendanceDialog = ref(false);
const viewLogs = ref(false);

// Computed: Disable Dropdown if Company is Selected
const isDropdownDisabled = computed(() => {
  return !!props.targetCompanyId && props.targetCompanyId !== 0;
});

// Computed: Type List for Filtering
const typeList = [
  { name: 'All', code: 'ALL' },
  { name: 'Late', code: 'LATE' },
  { name: 'Early', code: 'EARLY' },
  { name: 'Over Time', code: 'OVERTIME' },
  { name: 'Online', code: 'ONLINE' },
  { name: 'Not Approved', code: 'NOTAPPROVED' }
];
const selectedType = ref(typeList[0]);

// Function: Extract Username from Email
const extractUsername = (email) => {
  return email.split('@')[0];
};

// Computed: Format Logs for Display
const formatLogs = computed(() => (logs) => {
  return logs.map(log => ({
    ...log,
    username: extractUsername(log.user)
  }));
});

// State: Date Range for Filtering
const dateRange = ref(null);

// Function: Get Default Date Range
const getDefaultDate = () => {
  if (props.targetDateList) {
    const today = getLondonDate();
    if (props.targetDateList === 'THISMONTH') {
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return [
        getStartOfDay(firstDay),
        getEndOfDay(lastDay)
      ];
    }
  }
  const today = getLondonDate();
  return [
    getStartOfDay(today),
    getEndOfDay(today)
  ];
};

// Update getDateRangeParams function
const getDateRangeParams = () => {
  const params = {
    employeeId: selectedEmployee.value?.employee_id ?? 0,
    companyId: selectedCompany.value?.company_id ?? 0,
    startDate: null,
    endDate: null
  };

  if (dateRange.value && dateRange.value[0] && dateRange.value[1]) {
    params.startDate = getLondonDate(dateRange.value[0]).toISOString().split('T')[0];
    params.endDate = getLondonDate(dateRange.value[1]).toISOString().split('T')[0];
  }

  return params;
};

// Function: Refresh Data
const refreshData = async (employeeId, companyId, startDate, endDate) => {
  if (isRefreshing.value) return;
  isRefreshing.value = true;
  try {
    selectedRows.value = [];
    
    if (employeeId || companyId || startDate || endDate) {
      await fetchAllAttendance(employeeId, companyId, startDate, endDate);
    } else {
      console.log('No filters applied. Fetching all data or applying default behavior.');
    }
  } catch (err) {
    handleError(err, 'Fetching Attendance');
  } finally {
    isRefreshing.value = false;
  }
};

// Function: Handle Employee Selection
const handleEmployeeSelect = (event) => {
  onEmployeeSelect(event);
  const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
  refreshData(employeeId, companyId, startDate, endDate);
};

// Function: Handle Company Change
const handleCompanyChange = (event) => {
  onCompanyChange(event);
  const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
  refreshData(employeeId, companyId, startDate, endDate);
};

// Function: Handle Date Selection
const handleDateSelect = () => {
  if (dateRange.value && Array.isArray(dateRange.value) && dateRange.value[0] && dateRange.value[1]) {
    // Store the date range in Pinia
    selectedDateRange.value = dateRange.value;
    const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
    refreshData(employeeId, companyId, startDate, endDate);
  }
};

// Function: Handle Refresh Click
const handleRefreshClick = () => {
  const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
  refreshData(employeeId, companyId, startDate, endDate);
};

// Function: Handle Error
const handleError = (error, context) => {
  console.error(`Error in ${context}:`, error);
  let errorMessage = 'An unexpected error occurred.';
  if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }
  toast.add({
    severity: 'error',
    summary: `Error: ${context}`,
    detail: errorMessage,
    life: 8000,
    group: 'br'
  });
};

// Computed: Filtered Attendance
const filteredAttendance = computed(() => {
  if (!allAttendance.value || !Array.isArray(allAttendance.value)) {
    console.warn('allAttendance.value is not an array:', allAttendance.value);
    return [];
  }
  return allAttendance.value.filter(attendance => {
    if (selectedType.value.code === 'ALL') {
      return true;
    }
    switch (selectedType.value.code) {
      case 'ONLINE':
        return attendance.attendance_checkout_unix === null;
      case 'NOTAPPROVED':
        return attendance.attendance_status_out==='OVERTIME' && attendance.over_below_status === false;
      case 'LATE':
        return attendance.attendance_status_in==='LATE';
      case 'EARLY':
        return attendance.attendance_status_in==='EARLY' || attendance.attendance_status_out==='EARLY';
      case 'OVERTIME':
        return attendance.attendance_status_out==='OVERTIME';
      default:
        return true; 
    }
  });
});

// Computed: Paginated Attendance
const paginatedAttendance = computed(() => {
  const start = first.value;
  const end = start + rows.value;
  return filteredAttendance.value.slice(start, end);
});

// Computed: Check if Selected Rows Exist
const hasSelectedRows = computed(() => selectedRows.value && Object.keys(selectedRows.value).length > 0);

// Function: Handle Update Success
const handleUpdateSuccess = () => {
  const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
  editAttendanceDialog.value = false;
  selectedRows.value = [];
  refreshData(employeeId, companyId, startDate, endDate);
};

// Function: Handle Delete Success
const handleDeleteSuccess = handleUpdateSuccess;

// Function: Handle Back
const handleBack = () => {
  editAttendanceDialog.value = false;
  viewLogs.value = false;
  selectedRows.value = [];
};

// Function: Handle Page Change
const onPage = (event) => {
  first.value = event.first;
  rows.value = event.rows;
};

// Watch: Refresh Data on Prop Change
watch(() => props.targetEmployeeId || props.targetCompanyId, async (newValue) => {
  if (newValue) {
    const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
    await refreshData(employeeId, companyId, startDate, endDate);
  }
}, { immediate: true });

// Watch: Handle Attendance Error
watch(attendanceError, (newAttendanceError) => {
  if (newAttendanceError) {
    handleError(newAttendanceError, 'Attendance');
  }
});

// Lifecycle Hook: Initialize Component
onMounted(async () => {
  // First check for stored range, fallback to default if none exists
  dateRange.value = selectedDateRange.value || getDefaultDate();
  
  const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
  try {
    await refreshData(employeeId, companyId, startDate, endDate);
  } catch (error) {
    handleError(error, 'Component Initialization');
  }
});

// Add these computed properties after the other computed properties
const totalDuration = computed(() => {
  return filteredAttendance.value.reduce((total, row) => {
    const duration = calculateDuration(row.shift_in_unix, row.shift_out_unix);
    // Convert duration (HH:mm) to minutes
    const [hours, minutes] = duration.split(':').map(Number);
    return total + (hours * 60 + minutes);
  }, 0);
});

const totalWorkedHours = computed(() => {
  return filteredAttendance.value.reduce((total, row) => {
    if (row.attendance_checkout_unix > 0) {
      const duration = calculateDuration(row.attendance_checkin_unix, row.attendance_checkout_unix);
      // Convert duration (HH:mm) to minutes
      const [hours, minutes] = duration.split(':').map(Number);
      return total + (hours * 60 + minutes);
    }
    return total;
  }, 0);
});

const totalNetWorkedHours = computed(() => {
  return filteredAttendance.value.reduce((total, row) => {
    if (row.attendance_checkout_unix > 0) {
      const netHours = calculateNetWorkedHours(
        row.shift_in_unix,
        row.shift_out_unix,
        row.attendance_checkin_unix,
        row.attendance_checkout_unix,
        row.attendance_status_in,
        row.attendance_status_out,
        row.over_below_status
      );
      if (netHours && netHours.includes(':')) {  // Ensure valid HH:mm format
        const [hours, minutes] = netHours.split(':').map(Number);
        return total + (hours * 60 + minutes);
      }
      return total;
    }
    return total;
  }, 0);
});

// Helper function to convert minutes to HH:mm format
const formatMinutesToTime = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

// Add these computed properties
const calculateRowDurations = computed(() => (row) => {
  const shiftDuration = calculateDuration(row.shift_in_unix, row.shift_out_unix);
  const attendanceDuration = row.attendance_checkout_unix > 0 
    ? calculateDuration(row.attendance_checkin_unix, row.attendance_checkout_unix)
    : null;
  const netWorkedHours = row.attendance_checkout_unix > 0 
    ? calculateNetWorkedHours(
        row.shift_in_unix,
        row.shift_out_unix,
        row.attendance_checkin_unix,
        row.attendance_checkout_unix,
        row.attendance_status_in,
        row.attendance_status_out,
        row.over_below_status
      )
    : null;

  return {
    shiftDuration,
    attendanceDuration,
    netWorkedHours
  };
});
</script>