// stores/userSelectionStore.js
import { defineStore } from 'pinia'

export const useUserSelectionStore = defineStore('userSelection', {
  state: () => ({
    store_employee_id: null,
    store_employee_company_id: null,
    store_date_range: null
  }),
  actions: {
    setEmployeeId(id) {
      this.store_employee_id = id
    },
    setCompanyId(id) {
      this.store_employee_company_id = id
    },
    setDateRange(range) {
      this.store_date_range = range
    }
  }
})