<template>
    <div class="relative">
        <form @submit.prevent="handleSubmit">
            <div class="grid">
                <div class="col-12 md:col-4">
                    <h3>Employee Info</h3>
                    <div class="grid">
                        <div class="col-12 mb-1">
                            <label for="employee_id" class="block text-xs text-gray-400 mb-1">Employee ID <span class="text-red-500">*</span></label>
                            <InputText id="employee_id" v-model="employee.employee_id" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_name" class="block text-xs text-gray-400 mb-1">Name <span class="text-red-500">*</span></label>
                            <InputText id="employee_name" v-model="employee.employee_name" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_email" class="block text-xs text-gray-400 mb-1">Email <span class="text-red-500">*</span></label>
                            <InputText id="employee_email" v-model="employee.employee_email" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_badge_id" class="block text-xs text-gray-400 mb-1">Badge ID <span class="text-red-500">*</span></label>
                            <InputText id="employee_badge_id" v-model="employee.employee_badge_id" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_badge_pin" class="block text-xs text-gray-400 mb-1">Badge PIN</label>
                            <InputText id="employee_badge_pin" v-model="employee.employee_badge_pin" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_phone_number_main" class="block text-xs text-gray-400 mb-1">Phone Number (Main)</label>
                            <InputText id="employee_phone_number_main" v-model="employee.employee_phone_number_main" :disabled="isCreating" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_phone_number_secondary" class="block text-xs text-gray-400 mb-1">Phone Number (Secondary)</label>
                            <InputText id="employee_phone_number_secondary" v-model="employee.employee_phone_number_secondary" :disabled="isCreating" class="w-full" />
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <h3>Other Info</h3>
                    <div class="grid">
                        <div class="col-12 mb-1">
                            <label for="employee_dob" class="block text-xs text-gray-400 mb-1">Date of Birth</label>
                            <Calendar id="employee_dob" dateFormat="yy-mm-dd" v-model="employee.employee_dob" :disabled="isCreating" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_father_name" class="block text-xs text-gray-400 mb-1">Father's Name</label>
                            <InputText id="employee_father_name" v-model="employee.employee_father_name" :disabled="isCreating" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_cnic" class="block text-xs text-gray-400 mb-1">CNIC</label>
                            <InputText id="employee_cnic" v-model="employee.employee_cnic" :disabled="isCreating" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_gender" class="block text-xs text-gray-400 mb-1">Gender</label>
                            <Dropdown id="employee_gender" v-model="employee.employee_gender" :options="genderOptions" :disabled="isCreating" optionLabel="name" optionValue="code" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_next_of_kin" class="block text-xs text-gray-400 mb-1">Next of Kin</label>
                            <InputText id="employee_next_of_kin" v-model="employee.employee_next_of_kin" :disabled="isCreating" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_next_of_kin_number" class="block text-xs text-gray-400 mb-1">Next of Kin Number</label>
                            <InputText id="employee_next_of_kin_number" v-model="employee.employee_next_of_kin_number" :disabled="isCreating" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_address_permanent" class="block text-xs text-gray-400 mb-1">Permanent Address</label>
                            <Textarea 
                                id="employee_address_permanent" 
                                v-model="employee.employee_address_permanent" 
                                :disabled="isCreating" 
                                rows="3" 
                                class="w-full" 
                                autoResize 
                            />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_address_current" class="block text-xs text-gray-400 mb-1">Current Address</label>
                            <Textarea 
                                id="employee_address_current" 
                                v-model="employee.employee_address_current" 
                                :disabled="isCreating" 
                                rows="3" 
                                class="w-full" 
                                autoResize 
                            />
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <h3>Contract Info</h3>
                    <div class="grid">
                        <div class="col-12 mb-2">
                            <label for="employee_status" class="block text-xs text-gray-400 mb-1">Status <span class="text-red-500">*</span></label>
                            <Dropdown id="employee_status" v-model="employee.employee_status" :options="statusOptions" :disabled="isCreating" optionLabel="name" optionValue="code" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_department" class="block text-xs text-gray-400 mb-1">Department <span class="text-red-500">*</span></label>
                            <Dropdown id="employee_department" v-model="employee.employee_department" :disabled="isCreating" :options="departmentOptions" optionLabel="name" optionValue="code" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="tenant_id" class="block text-xs text-gray-400 mb-1">Tenant <span class="text-red-500">*</span></label>
                            <Dropdown id="tenant_id" v-model="employee.tenant_id" :options="tenantOptions" :disabled="isCreating" optionLabel="name" optionValue="code" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_company_id" class="block text-xs text-gray-400 mb-1">Company *</label>
                            <Dropdown id="employee_company_id" v-model="employee.employee_company_id" :disabled="isCreating" :options="companiesList" optionLabel="company_name" optionValue="company_id" :loading="employeesListLoading" class="w-full" required />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_join_date" class="block text-xs text-gray-400 mb-1">Join Date <span class="text-red-500">*</span></label>
                            <Calendar id="employee_join_date" dateFormat="yy-mm-dd" v-model="employee.employee_join_date" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_contract_date" class="block text-xs text-gray-400 mb-1">Contract Date <span class="text-red-500">*</span></label>
                            <Calendar id="employee_contract_date" dateFormat="yy-mm-dd" v-model="employee.employee_contract_date" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_contract" class="block text-xs text-gray-400 mb-1">Contract <span class="text-red-500">*</span></label>
                            <Dropdown id="employee_contract" v-model="employee.employee_contract" :options="contractOptions" :disabled="isCreating" optionLabel="name" optionValue="code" class="w-full" required />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_extra_bonuses" class="block text-xs text-gray-400 mb-1">Extra Bonuses</label>
                            <MultiSelect
                                id="employee_extra_bonuses"
                                v-model="employee.employee_extra_bonuses"
                                :options="bonusOptions"
                                :disabled="isCreating"
                                optionLabel="name"
                                optionValue="code"
                                display="chip"
                                class="w-full"
                                @change="handleBonusChange"
                                :optionDisabled="option => option.code === 'NONE'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <footer class="flex overflow-hidden mt-3">
            <div class="flex-shrink-0 flex align-items-start justify-content-start mr-2 mt-1">
                <Button label="Cancel" :disabled="isCreating" class="surface-200 hover:surface-400" @click="handleBack" aria-label="Cancel and go back" />
            </div>
            <div class="flex-grow-1 flex align-items-start justify-content-end">
                <Button label="Add Employee" :disabled="isCreating" :loading="isCreating" class="w-full bg-primary hover:surface-900 px-3 mt-1" @click="handleSubmit" aria-label="Submit" />
            </div>
        </footer>
    </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import { useEmployees } from '@/composables/api/useEmployees';
import { useEmployeeSingleCreate } from '@/composables/api/useEmployeeSingleCreate';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import MultiSelect from 'primevue/multiselect';

const emit = defineEmits(['handleBack', 'employeeCreated']);

const { companiesList, employeesListLoading } = useEmployees();
const { isCreating, createEmployee } = useEmployeeSingleCreate();
const toast = useToast();

const employee = ref({
    employee_id: '',
    employee_name: '',
    employee_email: '',
    tenant_id: '',
    employee_badge_id: '',
    employee_badge_pin: '',
    employee_status: '',
    employee_department: '',
    employee_company_id: null,
    employee_join_date: null,
    employee_contract_date: null,
    employee_contract: 'AUTOMATED',
    employee_extra_bonuses: ['NONE'],
    employee_phone_number_main: '',
    employee_phone_number_secondary: '',
    employee_dob: null,
    employee_father_name: '',
    employee_cnic: '',
    employee_gender: '',
    employee_next_of_kin: '',
    employee_next_of_kin_number: '',
    employee_address_permanent: '',
    employee_address_current: ''
});

const statusOptions = [   
    { name: 'Permanent', code: 'Permanent' },
    { name: 'Probation', code: 'Probation' },
    { name: 'Trainee', code: 'Trainee' },
    { name: 'Resigned', code: 'Resigned' },
    { name: 'Terminated', code: 'Terminated' }
];

const tenantOptions = [   
  { name: 'ORION', code: 'orion-6sgo6' },
  { name: 'IOT Expert', code: 'iotexpert-jyn65' },
  { name: 'Datacom', code: 'datacom-8rz4d' },
];

const departmentOptions = [   
    { name: 'CSR Staff', code: 'HR / Operator' },
    { name: 'Covering Staff', code: 'HR / Shift Cover' },
    { name: 'House keeping', code: 'House Keeping' },
    { name: 'Tech Staff', code: 'Tech' },
    { name: 'Manager', code: 'Manager' },
    { name: 'Maintenance', code: 'Maintenance' }
];

const genderOptions = [
    { name: 'Male', code: 'Male' },
    { name: 'Female', code: 'Female' },
    { name: 'Other', code: 'Other' }
];

const contractOptions = [
    { name: 'AUTOMATED 10', code: 'AUTOMATED10' },
    { name: 'AUTOMATED 8', code: 'AUTOMATED8' },
    { name: 'MANAGEMENT', code: 'MANAGEMENT' },
    { name: 'COVER', code: 'COVER' },
    { name: 'MANUAL', code: 'MANUAL' }
];

const bonusOptions = [
    { name: 'Travel', code: 'TRAVEL' },
    { name: 'Dispatch', code: 'DISPATCH' },
    { name: 'Team Lead', code: 'LEAD' },
    { name: 'NONE', code: 'NONE' }
];

const handleBack = () => {
    emit('handleBack');
};

const handleSubmit = async () => {
    // Check only required fields
    const requiredFields = [
        'employee_name',
        'employee_email',
        'tenant_id',
        'employee_badge_id',
        'employee_status',
        'employee_department',
        'employee_company_id',
        'employee_contract_date',
        'employee_join_date',
        'employee_contract'
    ];
    
    const missingFields = requiredFields.filter(field => !employee.value[field]);

    if (missingFields.length > 0) {
        // Convert technical field names to readable names for the error message
        const fieldNames = missingFields.map(field => {
            return field
                .replace('employee_', '')
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        });

        toast.add({
            severity: 'error',
            summary: 'Validation Error',
            detail: `Please fill in all required fields: ${fieldNames.join(', ')}`,
            life: 5000,
            group: 'br'
        });
        return;
    }

    // Prepare the payload
    const payload = { ...employee.value };
    
    // Only include employee_id if it's greater than 0
    if (parseInt(payload.employee_id) <= 0 || payload.employee_id === '') {
        delete payload.employee_id;
    }

    try {
        const createdEmployee = await createEmployee(payload);
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Employee created successfully',
            life: 3000,
            group: 'br'
        });
        // Notify the parent component
        emit('employeeCreated', createdEmployee);
    } catch (err) {
        let errorMessage = 'An error occurred while creating the employee';
        if (err.response && err.response.data) {
            if (err.response.status === 409) {
                errorMessage = `${err.response.data.error} Field: ${err.response.data.field}`;
            } else {
                errorMessage = err.response.data.error || errorMessage;
            }
        }
        toast.add({
            severity: 'error',
            summary: 'Creation Failed',
            detail: errorMessage,
            life: 5000,
            group: 'br'
        });
    }
};

const handleBonusChange = (event) => {
    if (!event.value || event.value.length === 0) {
        employee.value.employee_extra_bonuses = ['NONE'];
    }
};
</script>