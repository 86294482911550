// composables/useUserSelections.js
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserSelectionStore } from '@/stores/userSelectionStore';
import { useEmployees } from '@/composables/api/useEmployees';

export function useUserSelections() {
  const { employeesList, companiesList, employeesListLoading, fetchEmployees } = useEmployees();
  const userSelectionStore = useUserSelectionStore();
  const { store_employee_company_id, store_employee_id } = storeToRefs(userSelectionStore);

  const isUpdating = ref(false);

  const selectedCompany = computed({
    get: () => {
      if (store_employee_company_id.value) {
        return companiesList.value.find(company => company.company_id === store_employee_company_id.value) || null;
      }
      return null;
    },
    set: (value) => {
      if (!isUpdating.value) {
        isUpdating.value = true;
        userSelectionStore.setCompanyId(value ? value.company_id : null);
        userSelectionStore.setEmployeeId(null);
        isUpdating.value = false;
      }
    }
  });

  const selectedEmployee = computed({
    get: () => {
      if (store_employee_id.value) {
        return employeesList.value.find(employee => employee.employee_id === store_employee_id.value) || null;
      }
      return null;
    },
    set: (value) => {
      if (!isUpdating.value) {
        isUpdating.value = true;
        userSelectionStore.setEmployeeId(value ? value.employee_id : null);
        if (value) {
          userSelectionStore.setCompanyId(value.employee_company_id);
        }
        isUpdating.value = false;
      }
    }
  });

  const selectedDateRange = computed({
    get: () => {
      const storedRange = userSelectionStore.store_date_range;
      if (storedRange && storedRange[0] && storedRange[1]) {
        // Convert stored ISO strings back to Date objects for the Calendar
        return [new Date(storedRange[0]), new Date(storedRange[1])];
      }
      return null;
    },
    set: (value) => {
      if (!isUpdating.value) {
        isUpdating.value = true;
        // Only store the range when both dates are selected
        if (value && Array.isArray(value) && value[0] && value[1]) {
          userSelectionStore.setDateRange([value[0].toISOString(), value[1].toISOString()]);
        } else {
          // Don't update the store if we don't have a complete range
          userSelectionStore.setDateRange(null);
        }
        isUpdating.value = false;
      }
    }
  });

  const filteredEmployees = computed(() => {
    if (!employeesList.value || !Array.isArray(employeesList.value)) {
      console.warn('employeesList.value is not an array:', employeesList.value);
      return [];
    }

    const excludedStatuses = ['Resigned', 'Terminated', 'Hold', 'Cancelled'];
    
    return employeesList.value.filter(employee => {
      const companyMatch = !selectedCompany.value || employee.employee_company_id === selectedCompany.value.company_id;
      const statusCheck = !excludedStatuses.includes(employee.employee_status);
      return companyMatch && statusCheck;
    });
  });

  const onCompanyClear = () => {
    if (!isUpdating.value) {
      isUpdating.value = true;
      selectedCompany.value = null;
      selectedEmployee.value = null;
      isUpdating.value = false;
    }
  };

  const onEmployeeClear = () => {
    if (!isUpdating.value) {
      isUpdating.value = true;
      selectedEmployee.value = null;
      isUpdating.value = false;
    }
  };

  const onCompanyChange = (event) => {
    if (!isUpdating.value) {
      isUpdating.value = true;
      selectedCompany.value = event.value;
      selectedEmployee.value = null;
      isUpdating.value = false;
    }
  };

  const onEmployeeSelect = (event) => {
    if (!isUpdating.value) {
      isUpdating.value = true;
      let selectedValue = event.value || event.data;
      selectedEmployee.value = selectedValue;
      isUpdating.value = false;
    }
  };

  const onDateRangeChange = (range) => {
    if (!isUpdating.value) {
      isUpdating.value = true;
      selectedDateRange.value = range;
      isUpdating.value = false;
    }
  };

  return {
    selectedCompany,
    selectedEmployee,
    employeesListLoading,
    filteredEmployees,
    companiesList,
    onEmployeeClear,
    onCompanyClear,
    onCompanyChange,
    onEmployeeSelect,
    fetchEmployees,
    selectedDateRange,
    onDateRangeChange
  };
}