<template>
    <div class="flex mb-2">
        <div class="flex-0 flex align-items-center justify-content-center">
          <div class="px-0 py-2">
            <h2 v-if="selectedEmployee" class="m-0 p-0 font-light text-600">{{ pageTitle }}</h2>
            <h2 v-else-if="selectedCompany" class="m-0 p-0 font-light text-600">{{ selectedCompany.company_name }} <span class="capitalize">{{ props.viewType }}</span></h2>
            <h2 v-else class="m-0 p-0 font-light text-600">All <span class="capitalize">{{ props.viewType }}</span></h2>
            <p class="p-0 m-0 pt-1 text-xs text-300"> Showing {{ filteredRosters.length }} results.</p>
          </div>
        </div>
        <div class="flex-1 flex align-items-center gap-2 justify-content-end">
          <div style="position: absolute; right: 30px; bottom: 30px; z-index: 1;" v-if="selectedRows.length > 0" class="shadow-8 m-3">
            <Button v-if="selectedSingleRosterId" @click="viewLogs=true" label="View Logs" class="border-0 text-lg surface-300 hover:surface-500 mr-2 w-full mb-2" />
            <Button @click="openDialog" :label="`Edit ${props.viewType}`" class="px-4 py-3 text-lg w-full block shadow-3 surface-300 hover:surface-500 w-full" />
          </div>
          <Dropdown v-if="!isDropdownDisabled" v-model="selectedEmployee" :options="filteredEmployees" :loading="employeesListLoading" :disabled="isRefreshing" 
                    filter showClear optionLabel="employee_name" placeholder="All Employees" class="w-full md:w-16rem"  @change="handleEmployeeSelect" @clear="onEmployeeClear">
            <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                    <div>{{ slotProps.value.employee_name }}</div>
                </div>
                <span v-else class="text-sm text-300">
                    {{ slotProps.placeholder }}
                </span>
            </template>
            <template #option="slotProps">
                <div class="flex align-items-center">
                    <div>{{ slotProps.option.employee_name }}</div>
                </div>
            </template>
          </Dropdown>
          <Dropdown v-if="!isDropdownDisabled" v-model="selectedCompany" :options="companiesList" :loading="employeesListLoading" filter showClear 
                    :disabled="isRefreshing" optionLabel="company_name" placeholder="All Companies" class="w-full md:w-16rem" @change="handleCompanyChange" @clear="onCompanyClear">
            <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                    {{ slotProps.value.company_name }}
                </div>
                <span v-else class="text-sm text-300">
                    {{ slotProps.placeholder }}
                </span>
            </template>
            <template #option="slotProps">
                <div class="flex align-items-center">
                    {{ slotProps.option.company_name }}
                </div>
            </template>
          </Dropdown>
          <Dropdown v-if="props.viewType === 'roster'" v-model="selectedType" :options="typeList" :disabled="isRefreshing" optionLabel="name" placeholder="Type" class="w-full md:w-8rem">
            <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                    {{ slotProps.value.name }}
                </div>
                <span v-else class="text-sm text-300">
                    {{ slotProps.placeholder }}
                </span>
            </template>
          </Dropdown>
          <Calendar v-model="dateRange" 
                    selectionMode="range" 
                    :showIcon="true" 
                    :manualInput="false"
                    :disabled="isRefreshing"
                    dateFormat="yy-mm-dd"
                    placeholder="Select Date Range" 
                    class="w-full md:w-20rem"
                    :hideOnRangeSelection="true"
                    @date-select="handleDateSelect" />
          <div class="card flex justify-content-center" v-if="selectedCompanyId === 1">
              <Button icon="pi pi-plus" :disabled="!selectedEmployeeId || isRefreshing || props.viewType === 'leaves'" @click="toggle"  aria-haspopup="true" aria-controls="overlay_menu" class="surface-300 hover:bg-primary-600 mr-2" />
              <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />
          </div>
          <div class="card flex justify-content-center" v-else>
              <Button icon="pi pi-plus" :disabled="!selectedEmployeeId || isRefreshing || props.viewType === 'leaves'" @click="createRows(1, 'SHIFT')" class="surface-300 hover:bg-primary-600" />
          </div>
          <Button icon="pi pi-refresh" :loading="isRefreshing" @click="handleRefreshClick" class="surface-300 hover:surface-500" />
      </div>
    </div>
    <div v-if="filteredRosters.length">
      <DataTable :value="paginatedRosters" selectionMode="multiple" v-model:selection="selectedRows" :loading="isRefreshing">
        <Column selectionMode="multiple" class="w-1rem"></Column>
        <Column field="employee_name" header="Employee" class="text-600 w-3">
          <template #body="slotProps">
              <div class="flex items-center gap-2 align-content-center">
                  <img alt="flag" :src="slotProps.data.employee_photo_url || defaultUserImage" class="max-w-3rem max-h-3rem employee-photo	" />
                  <div>
                    <span class="block font-bold">{{ slotProps.data.employee_name }}</span>
                    <span class="block text-xs text-300">{{ slotProps.data.employee_company_name }}</span>
                    <span class="text-xs text-300 mr-2">
                      <span class="mr-2">@{{ slotProps.data.employee_id }}</span>
                      <span class="mr-2">{{ slotProps.data.employee_status }}</span>
                      <span class="mr-2">{{ slotProps.data.employee_department }}</span>
                    </span>
                  </div>
              </div>
          </template>
          <template #footer>
            <div class="flex surface-100 border-round p-2">
              <span class="text-xs font-light">Rows: <span class="font-bold">{{ filteredRosters.length }}</span></span>
            </div>
          </template>
        </Column>
        <Column field="roster_day" header="Day" class="text-600 w-1">
          <template #body="slotProps">
              <div class=" items-center gap-2">
                    <span class="block pb-1">{{ slotProps.data.roster_day }}</span>
                    <span class="block text-xs text-300">{{ slotProps.data.roster_month }}</span>
              </div>
          </template>
        </Column>
        <Column field="roster_start" header="Timing" class="text-600 w-3">
          <template #body="slotProps">
            <div class="flex gap-2">
                <div class="gap-2 line-height-3">
                      <span class="block flex"><span>{{ slotProps.data.roster_start }}</span></span>
                      <span class="block flex"><span>{{ slotProps.data.roster_end }}</span></span>
                </div>
              <div class="flex surface-100 align-items-center justify-content-center border-round p-2">
                <span class="text-400">{{ calculateDuration(slotProps.data.roster_start_unix, slotProps.data.roster_end_unix) }}</span> 
              </div>
            </div>
          </template>
          <template #footer>
            <div class="flex gap-2">
              <div class="flex surface-100 align-items-center justify-content-center border-round p-2">
                <span class="text-xs font-light">Roster: <span class="font-bold">{{ formatMinutesToTime(totalDuration) }}</span></span>
              </div>
            </div>
          </template>
        </Column>
        <Column header="Status" class="text-center w-1">
          <template #body="slotProps">
            <span v-if="slotProps.data.roster_marked">
            <Tag severity="info" class="block mb-1 surface-300 m-0 p-0" style="letter-spacing: 2px;">MARKED</Tag>
            </span>
            <Tag severity="info" :class="
                                  {'block bg-yellow-900 text-400 m-0 p-0': slotProps.data.shift_type==='LEAVE', 
                                  'block bg-cyan-900 text-400 m-0 p-0': slotProps.data.shift_type==='COVER', 
                                  'block surface-300 m-0 p-0': slotProps.data.shift_type==='SHIFT'}" style="letter-spacing: 2px;">
                                  {{ slotProps.data.shift_type }}
            </Tag>
          </template>
        </Column>
        <Column field="roster_day" header="Comments" class="text-600">
          <template #body="slotProps">
            <div v-if="slotProps.data.roster_logs && slotProps.data.roster_logs.length > 0">
              <div v-for="(log, index) in formatLogs(slotProps.data.roster_logs)" :key="index" class="mb-1">
                <span :class="{
                  'text-yellow-200': log.priority==='MEDIUM', 
                  'text-red-400': log.priority==='HIGH', 
                  'text-xs text-400 mr-2': true}">
                  @{{ log.username }}
                </span>
                <span :class="{
                  'text-yellow-200': log.priority==='MEDIUM', 
                  'text-red-400': log.priority==='HIGH', 
                  'text-xs text-400 mr-2': true}">
                  {{ log.message }}
                </span>
              </div>
            </div>
            <div v-else class="text-xs text-200">No comments</div>
          </template>
        </Column>
      </DataTable>
      <Paginator class="mt-2" :rows="rows" :totalRecords="filteredRosters.length" v-model:first="first" @page="onPage"></Paginator>
    </div>
    <div v-else>
      No {{ props.viewType }} found for this filter.
    </div>
    <Dialog v-model:visible="editRosterDialog" :style="{ width: '65vw' }" :draggable="false" :modal="true">
      <template #header>
        <h2><span class="capitalize">{{ props.viewType }}</span> Edit</h2>
      </template>
      <RosterUpdateDialog :selectedRosterIds="selectedRosterIds" :viewType="props.viewType" @updateSuccess="handleUpdateSuccess" @deleteSuccess="handleDeleteSuccess" @handleBack="handleBack" />
    </Dialog>

    <Dialog v-model:visible="createRosterDialog" :style="{ width: '70vw' }" maximizable :draggable="false" :modal="true">
      <template #header>
        <h2 class="m-0 p-0">Create Roster</h2>
      </template>
      
      <RosterCreateDialog 
        :selectedId="selectedEmployee" 
        :shiftType="shiftType" 
        :numberOfRows="numberOfRows" 
        @updateSuccess="handleUpdateSuccess" 
        @handleBack="handleBack"
        ref="rosterCreateRef" />
        
      <template #footer>
        <div class="flex w-full pt-3 border-t-1 border-300">
          <div class="flex-shrink-0 flex align-items-center justify-content-start mr-2">
            <Button label="Cancel" class="surface-200 hover:surface-400" @click="handleBack" />
          </div>
          <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-start">
            <Button label="Generate Roster" severity="primary" @click="generateRoster" :loading="rosterCreateRef?.isSubmitting" />
          </div>
          <div class="flex-shrink-0 flex align-items-center justify-content-end gap-1">
            <span class="text-300 text-xs pr-1">Add New Rows:</span>
            <Button label="+1" @click="handleCreateNewRows(1)" :disabled="rosterCreateRef?.isMaxRowsReached || rosterCreateRef?.isSubmitting" class="surface-300 hover:surface-500" />
            <Button label="+2" @click="handleCreateNewRows(2)" :disabled="rosterCreateRef?.isMaxRowsReached || rosterCreateRef?.isSubmitting" class="surface-300 hover:surface-500" />
            <Button label="+6" @click="handleCreateNewRows(6)" :disabled="rosterCreateRef?.isMaxRowsReached || rosterCreateRef?.isSubmitting" class="surface-300 hover:surface-500" />
            <Button label="+13" @click="handleCreateNewRows(13)" :disabled="rosterCreateRef?.isMaxRowsReached || rosterCreateRef?.isSubmitting" class="surface-300 hover:surface-500" />
            <Button label="+30" @click="handleCreateNewRows(30)" :disabled="rosterCreateRef?.isMaxRowsReached || rosterCreateRef?.isSubmitting" class="surface-300 hover:surface-500" />
            <Button label="+60" @click="handleCreateNewRows(60)" :disabled="rosterCreateRef?.isMaxRowsReached || rosterCreateRef?.isSubmitting" class="surface-300 hover:surface-500" />
          </div>
        </div>
      </template>
    </Dialog>

    <Dialog v-model:visible="viewLogs" :style="{ width: '60vw' }" maximizable :modal="true">
      <template #header>
        <h2>Logs</h2>
      </template>
      <LogsDialog :selectedIds="selectedSingleRosterId" logType="roster" @handleBack="handleBack" />
    </Dialog>
</template>

<script setup>
import { ref, onMounted, computed, watch, defineProps } from 'vue';
import { useRoster } from '@/composables/api/useRoster';
import { useToast } from 'primevue/usetoast';
import { useUtils } from '@/composables/useUtils';
import { useLondonUnixTime } from '@/composables/time/useLondonTime';
import { useUserSelections } from '@/composables/useUserSelections';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Dropdown from 'primevue/dropdown';
import Paginator from 'primevue/paginator';
import Dialog from 'primevue/dialog';
import Menu from 'primevue/menu';
import defaultUserImage from '@/assets/default-user.png';
import RosterUpdateDialog from '@/components/dialogs/RosterUpdateDialog.vue';
import RosterCreateDialog from '@/components/dialogs/RosterCreateDialog.vue';
import LogsDialog from '@/components/dialogs/LogsDialog.vue';
import Calendar from 'primevue/calendar';

// Props
const props = defineProps({
  targetEmployeeId: {
    type: Number,
    default: 0,
  },
  targetCompanyId: {
    type: Number,
    default: 0,
  },
  targetDateList: {
    type: String,
  },
  viewType: {
    type: String,
    default: 'roster',
    validator: (value) => ['roster', 'leaves'].includes(value)
  }
});

// Api: All Rosters 
const { allRosters, rosterErrors, fetchAllRosters } = useRoster(props.viewType);

// Utils: Calculate Duration
const { calculateDuration } = useUtils();

// Utils: London Date
const { 
  getLondonDate,
  getStartOfDay,
  getEndOfDay,
} = useLondonUnixTime();

// User Selections: Selected Employee, Company, and Employees List
const { 
  selectedEmployee, 
  employeesListLoading,
  selectedCompany, 
  filteredEmployees,
  companiesList,
  onEmployeeClear,
  onEmployeeSelect,
  onCompanyChange,
  onCompanyClear,
  selectedDateRange
} = useUserSelections();

// Toast: Display Messages
const toast = useToast();

// Refs
const editRosterDialog = ref(false);
const createRosterDialog = ref(false);
const numberOfRows = ref(0);
const shiftType = ref(0);
const viewLogs = ref(false);
const selectedRows = ref([]);
const first = ref(0);
const rows = ref(100);
const menu = ref();
const isRefreshing = ref(false);
const dateRange = ref(null);
const rosterCreateRef = ref(null);

// Logs: Extract Username
const extractUsername = (email) => {
  return email.split('@')[0];
};

// Computed: Check if Dropdown is Disabled
const isDropdownDisabled = computed(() => {
  return !!props.targetCompanyId && props.targetCompanyId !== 0;
});

// Computed: Format Logs
const formatLogs = computed(() => (logs) => {
  return logs.map(log => ({
    ...log,
    username: extractUsername(log.user)
  }));
});

// Computed: Menu Items
const menuItems = computed(() => {
  const baseItems = [
    {
      label: 'Select Type',
      items: [
        {
          label: 'Shift',
          command: () => {
            createRows(1, 'SHIFT');
          }
        }
      ]
    }
  ];

  if (selectedCompanyId.value === 1) {
    baseItems[0].items.push({
      label: 'Cover',
      command: () => {
        createRows(1, 'COVER');
      }
    });
  }

  return baseItems;
});

// Menu: Toggle Menu
const toggle = (event) => {
    menu.value.toggle(event);
};

// Function: Create Rows
const createRows = (times, type) => {
  numberOfRows.value = times;
  shiftType.value = type;
  createRosterDialog.value = true
};

// Computed: Selected Employee ID
const selectedEmployeeId = computed(() => selectedEmployee.value?.employee_id || null);

// Computed: Selected Company ID
const selectedCompanyId = computed(() => selectedEmployee.value?.employee_company_id || selectedCompany.value?.company_id || null);

// Computed: Selected Single Roster ID
const selectedSingleRosterId = computed(() => {
  if (Array.isArray(selectedRows.value)) {
    if (selectedRows.value.length === 1) {
      // Return the roster_id if it exists, otherwise null
      return selectedRows.value[0]?.roster_id ?? null;
    } else if (selectedRows.value.length > 1) {
      return null;
    }
  }
  return null;
});

// Computed: Selected Roster IDs
const selectedRosterIds = computed(() => {
  if (Array.isArray(selectedRows.value) && selectedRows.value.length > 0) {
    return selectedRows.value.map(row => row.roster_id).filter(id => id != null);
  }
  return [];
});

// Function: Open Roster Edit Dialog
const openDialog = () => {
  editRosterDialog.value = true;
};

// Computed: Type List
const typeList = [
  { name: 'All', code: 'ALL' },
  { name: 'Shift', code: 'SHIFT' },
  { name: 'Covers', code: 'COVER' },
  { name: 'Leaves', code: 'LEAVE' }
];
const selectedType = ref(typeList[0]);

// Function: Get Default Date
const getDefaultDate = () => {
  if (selectedDateRange.value) {
    return selectedDateRange.value;
  }
  const today = getLondonDate();
  return [
    getStartOfDay(today),
    getEndOfDay(today)
  ];
};

// Function: Get Date Range Params
const getDateRangeParams = () => {
  const params = {
    employeeId: selectedEmployee.value?.employee_id ?? 0,
    companyId: selectedCompany.value?.company_id ?? 0,
    startDate: null,
    endDate: null
  };

  if (dateRange.value && dateRange.value[0] && dateRange.value[1]) {
    params.startDate = getLondonDate(dateRange.value[0]).toISOString().split('T')[0];
    params.endDate = getLondonDate(dateRange.value[1]).toISOString().split('T')[0];
  }

  return params;
};

// Function: Handle Date Select
const handleDateSelect = () => {
  if (dateRange.value && Array.isArray(dateRange.value) && dateRange.value[0] && dateRange.value[1]) {
    // Store the date range in Pinia
    selectedDateRange.value = dateRange.value;
    const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
    refreshData(employeeId, companyId, startDate, endDate);
  }
};

// Function: Handle Refresh Click
const handleRefreshClick = () => {
  const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
  refreshData(employeeId, companyId, startDate, endDate);
};

// Function: Refresh Data
const refreshData = async (employeeId, companyId, startDate, endDate) => {
  if (isRefreshing.value) return;
  isRefreshing.value = true;
  try {
    
    selectedRows.value = [];
    if (employeeId || companyId || startDate || endDate) {
      await fetchAllRosters(employeeId, companyId, startDate, endDate);
    } else {
      console.log('No filters applied. Fetching all data or applying default behavior.');
    }
  } catch (err) {
    handleError(err, 'Fetching Roster');
  } finally {
    isRefreshing.value = false;
  }
};

// Watch: Refresh Data on Prop Change
watch(() => props.targetEmployeeId || props.targetCompanyId, async (newValue) => {
  if (newValue) {
    const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
    await refreshData(employeeId, companyId, startDate, endDate);
  }
}, { immediate: true });

// Function: Handle Employee Selection
const handleEmployeeSelect = (event) => {
  onEmployeeSelect(event);
  const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
  refreshData(employeeId, companyId, startDate, endDate);
};

// Function: Handle Company Change
const handleCompanyChange = (event) => {
  onCompanyChange(event);
  const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
  refreshData(employeeId, companyId, startDate, endDate);
};

// Function: Handle Error
const handleError = (error, context) => {
  console.error(`Error in ${context}:`, error);
  
  let errorMessage = 'An unexpected error occurred.';
  if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }

  toast.add({
    severity: 'error',
    summary: `Error: ${context}`,
    detail: errorMessage,
    life: 12000,
    group: 'br'
  });
};

// Computed: Filtered Rosters
const filteredRosters = computed(() => {
  if (!allRosters.value || !Array.isArray(allRosters.value)) {
    console.warn('allRosters.value is not an array:', allRosters.value);
    return [];
  }
  return allRosters.value.filter(attendance => {
    if (selectedType.value.code === 'ALL') {
      return true;
    }
    switch (selectedType.value.code) {
      case 'MARKED':
        return attendance.roster_marked === true;
      case 'OPEN':
        return attendance.roster_marked === false;
      case 'SHIFT':
      case 'COVER':
      case 'LEAVE':
        return attendance.shift_type === selectedType.value.code;
      default:
        return true; 
    }
  });
});

// Computed: Paginated Rosters
const paginatedRosters = computed(() => {
  const start = first.value;
  const end = start + rows.value;
  return filteredRosters.value.slice(start, end);
});

// Function: Handle Update Success
const handleUpdateSuccess = () => {
  const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
  editRosterDialog.value = false;
  createRosterDialog.value = false;
  selectedRows.value = [];
  refreshData(employeeId, companyId, startDate, endDate);
};

// Function: Handle Delete Success
const handleDeleteSuccess = handleUpdateSuccess;

// Function: Handle Back
const handleBack = () => {
  editRosterDialog.value = false;
  createRosterDialog.value = false;
  viewLogs.value = false;
  selectedRows.value = [];
};

// Function: Handle Page Change
const onPage = (event) => {
  first.value = event.first;
  rows.value = event.rows;
};

// Watch: Refresh Data on Target Date List Change
watch(() => props.targetDateList, (newValue) => {
  if (newValue) {
    dateRange.value = getDefaultDate();
    const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
    refreshData(employeeId, companyId, startDate, endDate);
  }
});

// Watch: Handle Roster Errors
watch(rosterErrors, (newRosterError) => {
  if (newRosterError) {
    handleError(newRosterError, 'Roster');
  }
});

// Initialization: Fetch Data on Component Mount
onMounted(async () => {
  // First check for stored range, fallback to default if none exists
  dateRange.value = selectedDateRange.value || getDefaultDate();
  
  const { employeeId, companyId, startDate, endDate } = getDateRangeParams();
  try {
    await refreshData(employeeId, companyId, startDate, endDate);
  } catch (error) {
    handleError(error, 'Component Initialization');
  }
});

// Add computed property for page title
const pageTitle = computed(() => {
  return props.viewType === 'leaves' 
    ? `${selectedEmployee.value?.employee_name || selectedCompany.value?.company_name || 'All'} Leaves`
    : `${selectedEmployee.value?.employee_name || selectedCompany.value?.company_name || 'All'} Roster`;
});

const generateRoster = () => {
  rosterCreateRef.value?.submitForm();
};

const handleCreateNewRows = (count) => {
  rosterCreateRef.value?.createNewRows(count);
};

// Add these computed properties after the other computed properties
const totalDuration = computed(() => {
  return filteredRosters.value.reduce((total, row) => {
    const duration = calculateDuration(row.roster_start_unix, row.roster_end_unix);
    // Convert duration (HH:mm) to minutes
    const [hours, minutes] = duration.split(':').map(Number);
    return total + (hours * 60 + minutes);
  }, 0);
});

// Helper function to convert minutes to HH:mm format
const formatMinutesToTime = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};
</script>